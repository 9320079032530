import * as React from "react"
import Template from "../components/Template"
import SEO from "../components/SEO"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import noImage from "../styles/images/no-image-blog.png"
import formatDate from "../utils/Date"
import {Link} from "gatsby"
// markup
const Comunicados = (props) => {
    const posts=props.data.allSanityEntrada.nodes
    const estilos={
        color: "var(--darkText)",
        textDecoration: "none"
      }
  return (
    <Template>
      <SEO title="Comunicados"/>
      <Container style={{marginBottom: "8em"}}>
        <h1 style={{marginTop: "2em", textAlign: "center"}}>Comunicados</h1>
        <p style={{textAlign:"center", marginTop:"1em", marginBottom:"3em"}} className="noSmall">{props.data.sanityGlobalConfig.textComunicados}</p>
       
        {
            posts.map(post=>{
                let textoSegmented=[]
                post.cuerpo.forEach(child=>{
                    child.children.forEach(segmento=>{
                        textoSegmented.push(segmento.text)
                    })
                   
                })
                const texto=textoSegmented.join("")
                return(
               
                    <Row class="previewContainer" style={{marginBottom:"2em"}}>
                        <Col lg={3} md={4} sm={12}> 
                          <Link to={"/blog/"+post.slug.current} style={estilos}>
                            <img className="blogImagePreview" src={post.image? post.image.asset.url+"?w=300" : noImage}/>
                          </Link>
                        </Col>
                        <Col md={9} md={8} sm={12}>
                            <div>
                              <h3><Link to={"/blog/"+post.slug.current} style={estilos}>{post.title}</Link></h3>
                            </div>
                            <div><small class="labelFicha">Escrito por {post.asesor.name} el {formatDate(post.fechaPublicacion)}</small></div>
                            <div class="excerptPreview">{(texto.length > 400) ? texto.substr(0, 500-1) + '...' : texto }<div className="read-more"></div></div>
                        </Col>
                    </Row>
                
                )
            })
        }
      </Container>
    </Template>
  )
}


export default Comunicados

export const query = graphql`
  query{
    sanityGlobalConfig{
      textComunicados
    }
    allSanityEntrada ( sort:{
          fields: [fechaPublicacion]
          order: DESC
        }) {
      nodes {
        fechaPublicacion
        slug{
          current
        }
        asesor {
          name
          image {
            asset {
              url
            }
          }
        }
        title
        cuerpo{
          children{
            text
          }
        }
        image{
          asset{
            url
          }
        }
      }
    }
  }
  
`
